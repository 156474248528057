const Colaboradores = () => {
  return (
    <section className="container-flex-row tam-section-lg">
      <div className="child-comerciantes-items-woman">
        <div className="child-container-text font-ubuntu">
          <h1 className="tittle-web">Para tus colaboradores</h1>
          <p className="text-web">
            Con Black no solo ganás vos, si no también tus colaboradores.
          </p>

          <div className="container-flex">
            <div className="container-card-flex-comerciante mb-4">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/guita_cqf5y8.png" alt="sueldo" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[65%] mb-2">
                  Créditos personales y adelanto de sueldo
                </h5>
                <p className="text-[1em] font-[300]">De manera 100% online.</p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684115/WALLET%20BLACK/casaIcon_delnvc.png" alt="hipotecario" loading="lazy" decoding="async" width={24} height={29} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[65%] mb-2">
                  Crédito hipotecario y prendario
                </h5>
                <p className="text-[1em] font-[300]">
                  Para compra de su terreno, casa, moto o vehículo.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684340/WALLET%20BLACK/creditoo_gku1xq.png" alt="credito" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[65%] mb-2">
                  Tarjeta de crédito Black
                </h5>
                <p className="text-[1em] font-[300]">
                  Tarjeta Visa Black crédito, a su disposición.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/candadoo_h5wztr.png" alt="seguros" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[65%] mb-2">
                  Seguros{" "}
                </h5>
                <p className="text-[1em] font-[300]">
                  Descuento de hasta un 10%.{" "}
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/llavee_ryhltl.png" alt="alquiler" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[65%] mb-2">
                  Garantías de alquiler
                </h5>
                <p className="text-[1em] font-[300]">
                  Nuestra garantía de alquiler con un descuento deL 50%.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/foquito_t5yf8i.png" alt="inversiones" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[65%] mb-2">
                  Inversiones
                </h5>
                <p className="text-[1em] font-[300]">
                  Podrán acceder a invertir su dinero en el portfolio.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="child-container-media">
          <img
            width={300}
            height={597}
            src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672685123/WALLET%20BLACK/woman_dxwyiu.png"
            alt="mujer"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </section>
  );
};

export default Colaboradores;
