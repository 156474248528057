import { useState, useEffect } from "react";
import Hero from '../../Components/Hero/Hero';
import { useSpring, animated } from "react-spring";
import { useMediaQuery } from 'react-responsive';
import PdfComisiones from './Comisiones NOV-DIC 23.pdf';
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import './comision.css';

// Import sections
import Slider1 from "./Slider1";
import Slider2 from "./Slider2";

export default function Comision() {

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)", });
  const vistaNetbook = useMediaQuery({ query: "(min-width:1024px) and (max-width: 1440px)" });

  const [scrollPosition, setScrollPosition] = useState(0);
  const [my_swiper, set_my_swiper] = useState({});

  useEffect(() => {
    setInterval(function () {
      setScrollPosition(window.scrollY)
    });

  }, []);

  useEffect(() => {
    if (scrollPosition >= 1500 && !vistaNetbook && !vistaMobile) {
      my_swiper.slideNext();
    }
    if (scrollPosition < 1500 && scrollPosition > 0 && !vistaNetbook && !vistaMobile) {
      my_swiper.slidePrev();
    }
    if (scrollPosition >= 2000 && !vistaNetbook && !vistaMobile) {
      my_swiper.slidePrev();
    }
    if (scrollPosition >= 1299 && vistaNetbook && !vistaMobile) {
      my_swiper.slideNext();
    }
    if (scrollPosition < 1299 && scrollPosition > 0 && vistaNetbook && !vistaMobile) {
      my_swiper.slidePrev();
    }
    if (scrollPosition >= 1700 && vistaNetbook && !vistaMobile) {
      my_swiper.slidePrev();
    }
    // eslint-disable-next-line
  }, [scrollPosition]);

  return (
    <main className="comisiones">
      <Helmet>
          <title>Comisiones - Black Wallet</title>
          <meta name="description" content="Revise las comisiones de la tarjeta Black" />
      </Helmet>

      <animated.div style={fadeOut} className={"comision-container"}>

        {/* HERO */}
        {/* HERO */}
        <Hero
          backgroundVideoMobile="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1676041727/WALLET%20BLACK/Videos/Web_Comisiones_Mobile_1_lbmw95.mp4"
          backgroundVideo="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1672840008/WALLET%20BLACK/web_comisiones_iot0av.webm"
          titulo="A solo $0"
          poster="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1676042213/WALLET%20BLACK/Videos/Posters/web_comisiones_mobile_1_wuowed.png"
          subtitulo="Los mejores beneficios, sin los costos asociados a una cuenta bancaria"
          botonesDescargar="Activar"
        />

        {/* NO TIENE COSTO */}
        {/* NO TIENE COSTO */}
        <div className='child-container-slide'>
          <div className="slider-section-container-comision" id="slider-home">
            {!vistaMobile ?
              <>
                <div className="section-slider-comision">
                  <Swiper
                    allowSlidePrev
                    onInit={(ev) => {
                      set_my_swiper(ev)
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      1200: {
                        slidesPerView: 1.7,
                        spaceBetween: 30,
                      },
                      1500: {
                        slidesPerView: 1.5,
                        spaceBetween: 0,
                      }
                    }}
                    slidesPerView={2}
                    spaceBetween={0}
                    className="slider-fotos"
                  >
                    <SwiperSlide>
                      <Slider1 vistaMobile={vistaMobile} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <Slider2 />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </>
              :
              <>
              {/* SLIDE 1 */}
              <div className="container-flex-column">
                <Slider1 vistaMobile={vistaMobile} />
              </div>

              {/* SLIDE 2 */}
              <div className="container-flex-column">
                <Slider2 />
                <div className='container-comision-subtext'>
                  <p> * Con dinero disponible en la Black Wallet de tu cliente y acreditación inmediata.<span> <a href={PdfComisiones} target="_blank" rel="noreferrer noopener">Descargá la planilla completa de costos</a> </span> </p>
                </div>
              </div>
            </>
          }
        </div>
      </div>

        <div className="bloque-separate">
          {/* se puede volar el bloque cuando quieras  */}
        </div>

      </animated.div>
    </main>
  )
}
