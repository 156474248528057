import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper";
import qr from '../../assets/qr.svg';
import { LazyVideo } from "../../Components/Lozad/Lozad";

const TelefonoCards = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 787px)" });

  return (
    <section className="home-telefonocards">
      {!vistaMobile ? (
        <div className="container-cards mt-24 tam-margin-xl">
          <div className="container-media-middle">
            <video width={650} autoPlay muted loop>
              <source src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1692978917/WALLET%20BLACK/celus_web-_nlsvtb.webm"></source>
            </video>
          </div>
          <div className="container-medialuna">
            <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672250559/WALLET%20BLACK/elipseWallet_chptzk.png" alt="medialuna" loading="lazy" decoding="async" width={1098} height={571} />
          </div>
          <div className="container-home-cards-top">
            <div className="child-home-cards flex flex-col text-center items-center font-ubuntu ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" alt="enviar" loading="lazy" decoding="async" width={56} height={56} />
              <h5 className="mb-3 font-[500] text-[1.4rem]">Envía dinero</h5>
              <p className="">
                Al instante y sin costo, entre usuarios Black y de otras wallets
              </p>
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards flex flex-col text-center items-center font-ubuntu ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672071188/WALLET%20BLACK/circulo_ticket_oychiq.svg" alt="paga" loading="lazy" decoding="async" width={56} height={56} />
              <h5 className="mb-3 font-ubuntu font-[500] text-[1.4rem]">
                Pagá tus servicios
              </h5>
              <p className="w-[80%]">
                Desde tu Black Wallet, sin moverte de casa
              </p>
            </div>
          </div>
          <div className="container-home-cards-middle">
            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards flex flex-col text-center items-center font-ubuntu ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672314708/WALLET%20BLACK/recibiDinero_rbppbq.png" alt="recibi" loading="lazy" decoding="async" width={56} height={56} />
              <h5 className="mb-3 font-ubuntu  font-[500] text-[1.4rem]">
                Recibí dinero
              </h5>
              <p className="w-[80%] font-ubuntu">
                Cobrá al instante y sin comisiones, entre usuarios Black y de
                otras wallets
              </p>
            </div>

            <div className="child-home-cards flex flex-col text-center items-center font-ubuntu ">
              <img src={qr} alt="qr" loading="lazy" decoding="async" width={56} height={56} />
              <h5 className="mb-3 font-ubuntu font-[500] text-[1.4rem]">
                Cobrá con QR
              </h5>
              <p className="font-ubuntu w-[80%]">
                Un QR personalizado para tu negocio, y sin comisiones durante
                los primeros 3 meses.
              </p>
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>
          </div>
          <div className="container-home-cards-bottom">
            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672071356/WALLET%20BLACK/ciruclo_tarjeta_nmblmk.png" />
              <h5>Cobrá con tarjetas</h5>
              <p>En cuotas con las tasas y comisiones más convenientes</p> */}
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>

            <div className="child-home-cards">
              {/* <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" />
            <h5>Envía dinero</h5>
            <p>Al instante y sin costo,
              entre usuarios Black y
              de otras wallets</p> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="slider-mobile-home">
          <div className="image-pre-slider-container">
            {/* <img alt="phone-black" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1673014982/WALLET%20BLACK/Frame_412_tcnfyr.png" /> */}
            <div
              className={"video-container"}
            >
              <LazyVideo
                src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675881869/WALLET%20BLACK/Videos/Celus_wur09r.mp4"}
                type={'video/mp4'}
                poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1676038531/WALLET%20BLACK/Videos/Posters/celus_enwzex.png"}
                width={500}
                height={500}
              />
            </div>
          </div>
          <Swiper
            grabCursor={true}
            effect={"creative"}
            initialSlide={0}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ["-100%", "-60px", 0],
              },
              next: {
                translate: ["100%", "-60px", 0],
              },
            }}
            slidesPerView={2}
            centeredSlides={true}
            modules={[EffectCreative]}
            className="swipper-mobile-home"
          >
            <SwiperSlide>
              <div className="child-home-cards flex items-center justify-center flex-col">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672249090/WALLET%20BLACK/enviar-dinero_ybr64w.png" alt="envia" loading="lazy" decoding="async" width={50} height={50} />
                <h5>Envía dinero</h5>
                <p>
                  Al instante y sin costo, entre usuarios Black y de otras
                  wallets
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="child-home-cards flex items-center justify-center flex-col">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672314708/WALLET%20BLACK/recibiDinero_rbppbq.png" alt="recibi" loading="lazy" decoding="async" width={50} height={50} />
                <h5>Recibí dinero</h5>
                <p>
                  Cobrá al instante y sin comisiones, entre usuarios Black y de
                  otras wallets
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="child-home-cards flex items-center justify-center flex-col">
                <img src={qr} alt="qr" loading="lazy" decoding="async" width={50} height={50} />
                <h5>Cobrá con QR</h5>
                <p>
                  Un QR personalizado para tu negocio, y sin comisiones durante
                  los primeros 3 meses.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="child-home-cards flex items-center justify-center flex-col">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672071188/WALLET%20BLACK/circulo_ticket_oychiq.svg" alt="paga" loading="lazy" decoding="async" width={50} height={50} />
                <h5>Pagá tus servicios</h5>
                <p>Desde tu Black Wallet, sin moverte de casa</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}
    </section>
  );
};

export default TelefonoCards;
