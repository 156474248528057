import { Swiper, SwiperSlide } from "swiper/react";
import elementosLogo from '../logo-elementos.webp';
import merciLogo from '../merci-logo.svg';

const Servicios = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-container-beneficios">
        <p className="titulo-slider-beneficio mb-4">Servicios</p>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 4.6,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5.8,
              spaceBetween: 30,
            },
          }}
          slidesPerView={6}
          spaceBetween={0}
        >
          {/* <SwiperSlide className="swipper-beneficios-con-logo">
                      <div className="box-image-logo-container">
                        <div className="box-image-logo-container">
                          <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690482959/WALLET%20BLACK/Beneficios/Group_8_k3fobt.svg" />
                        </div>
                      </div>
                      <h5 className="titulo-comentario-cliente">
                        -40% los lunes
                      </h5>
                      <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                        Calle 37 N°125 entre 117 y 118
                      </p>
                    </SwiperSlide> */}

          <SwiperSlide className="swipper-beneficios-con-logo">
            <a href="https://flexy.com.ar/" target="_blank" rel="noreferrer noopener">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690482959/WALLET%20BLACK/Beneficios/Group_9_hkdvjw.svg" alt="flexy" loading="lazy" decoding="async" width={119} height={39} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Calle 37 N°125 entre 117 y 118
              </p>
            </a>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <a href="https://trustfund.com.ar/" target="_blank" rel="noreferrer noopener">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690482959/WALLET%20BLACK/Beneficios/Trust_Found_1_d1iyjp.svg" alt="trustfund" loading="lazy" decoding="async" width={172} height={33} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Hasta 10% off</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Calle 37 N°125 entre 117 y 118
              </p>
            </a>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <a href="https://enoff.com.ar" target="_blank" rel="noreferrer noopener">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690482959/WALLET%20BLACK/Beneficios/Enoff_1_r7rhrd.svg" alt="enoff" loading="lazy" decoding="async" width={54} height={72} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                www.enoff.com.ar
              </p>
            </a>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <a href="https://desarrollosdelsud.com.ar/" target="_blank" rel="noreferrer noopener">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1699296544/WALLET%20BLACK/Beneficios/desarrollos_npxuan.svg" alt="desarrollos" loading="lazy" decoding="async" width={103} height={51} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Hasta 10% off</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                PARA USUARIOS EXISTENTES
              </p>
            </a>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <a href="https://mercibroker.com.ar" target="_blank" rel="noreferrer noopener">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src={merciLogo} alt="Merci" loading="lazy" decoding="async" width={168} height={63} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">
                Hasta 20% de reintegro
              </h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                www.mercibroker.com.ar
              </p>
            </a>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <a
              href="https://elementalconstructora.com.ar/elementos"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src={elementosLogo} alt="Elementos" loading="lazy" decoding="async" width={70} height={80} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Crédito Personal</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                www.elementalconstructora.com.ar
              </p>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </article>
  );
};

export default Servicios;
