import { Swiper, SwiperSlide } from "swiper/react";

const Deportes = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-container-beneficios">
        <p className="titulo-slider-beneficio mb-4">Deportes e Instituciones</p>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
          }}
          slidesPerView={6}
          spaceBetween={0}
        >
          {/* <SwiperSlide className="swipper-beneficios-con-logo">
                      <div className="box-image-logo-container">
                        <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690481501/WALLET%20BLACK/Beneficios/Capa_1_ynmk03.svg" />
                      </div>
                      <h5 className="titulo-comentario-cliente">
                        Pagá con Black
                      </h5>
                      <p className="descripcion-comentario-cliente text-[12px]">
                        Sponsor oficial
                      </p>
                    </SwiperSlide> */}
          {/* <SwiperSlide className="swipper-beneficios-con-logo">
                      <div className="box-image-logo-container">
                        <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690481501/WALLET%20BLACK/Beneficios/banfield_1_emzqym.svg" />
                      </div>
                      <h5 className="titulo-comentario-cliente">
                        Pagá con Black
                      </h5>
                      <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                        Sponsor oficial
                      </p>
                    </SwiperSlide> */}
          {/* <SwiperSlide className="swipper-beneficios-con-logo">
                      <div className="box-image-logo-container">
                        <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690481502/WALLET%20BLACK/Beneficios/Escudo_del_Club_Santa_Marina_de_Tandil_1_ildkhq.svg" />
                      </div>
                      <h5 className="titulo-comentario-cliente">
                        Pagá con Black
                      </h5>
                      <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                        Sponsor oficial
                      </p>
                    </SwiperSlide> */}
          <SwiperSlide className="swipper-beneficios-con-logo">
            <div className="box-image-logo-container">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690481502/WALLET%20BLACK/Beneficios/circulo_dqisqk.svg" alt="circulo" loading="lazy" decoding="async" width={56} height={91} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
              Sponsor oficial
            </p>
          </SwiperSlide>
          {/* <SwiperSlide className="swipper-beneficios-con-logo">
                      <div className="box-image-logo-container">
                        <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690827042/WALLET%20BLACK/Beneficios/La_Plata_Rugby_Club_1_klaxda.svg" />
                      </div>
                      <h5 className="titulo-comentario-cliente">
                        Pagá con Black
                      </h5>
                      <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                        Sponsor oficial
                      </p>
                    </SwiperSlide> */}
          {/* <SwiperSlide className="swipper-beneficios-con-logo">
                      <div className="box-image-logo-container">
                        <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690481501/WALLET%20BLACK/Beneficios/San_luis_pvmolv.svg" />
                      </div>
                      <h5 className="titulo-comentario-cliente">
                        Pagá con Black
                      </h5>
                      <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                        Sponsor oficial
                      </p>
                    </SwiperSlide> */}
        </Swiper>
      </div>
    </article>
  );
};

export default Deportes;
