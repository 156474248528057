import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import NavbarWallet from '../Components/NavbarWallet/NavbarWallet'
import Home from '../Pages/Home/Home'
// import MaintenancePage from '../Components/MaintenancePage/MaintenancePage'
import Nosotros from '../Pages/Nosotros/Nosotros'
import Comision from '../Pages/Comision/Comision'
import Beneficios from '../Pages/Beneficios/Beneficios'
import Preguntas from '../Pages/Preguntas/Preguntas'
// import Robo from '../Pages/Robo/Robo'
import Comerciantes from '../Pages/Comerciantes/comerciantes'
// import Tarjetas from '../Pages/Tarjetas/Tarjetas' 
// import PromoComerciantes from '../Pages/PromoComerciantes/PromoComerciantes'
// import GraciasPromo from '../Pages/PromoComerciantes/GraciasPromo'
import Footer from '../Components/Footer/Footer'
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop'

export default function Rutas() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                    <NavbarWallet />
                    <Routes>
                        <Route path="/" element={<Home />} />

                        {/* RUTAS DE LA PROMO */}
                        {/* <Route path="/" element={<PromoComerciantes />} />
                         <Route path="/solicitud-enviada" element={<GraciasPromo />} /> */}

                        {/* <Route path="/" element={<MaintenancePage />} /> */}

                        <Route path="/nosotros" element={<Nosotros />} />
                        <Route path="/comisiones" element={<Comision />} />
                        <Route path="/beneficios" element={<Beneficios />} />
                       {/* <Route path='/tarjetas' element={<Tarjetas />} />  */}
                        <Route path="/preguntas-frecuentes" element={<Preguntas />} />
                        {/* <Route path="/robo-o-extravio" element={<Robo />} /> */}
                        <Route path="/comerciantes" element={<Comerciantes />} />
                    </Routes>
                    <Footer />
                </ScrollToTop>
            </BrowserRouter>
        </>
    )
}
