import { useState, useEffect } from 'react'

import { useMediaQuery } from 'react-responsive'
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './navbarWallet.css';

export default function NavbarWallet() {
  const [show, setShow] = useState(false);
  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  })

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  
  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)", });

  return (
    <>
      <Navbar expand={'xl'}  className={navbar===true ? "container-navbar-fixed se:h-[15vh] z-[10] xs:h-[20vh]" : "container-navbar"}>
        <Container>
          <Navbar.Brand as={Link} to="/" >
            <img className='icon-black' width={160} height={150} src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1671551488/WALLET%20BLACK/icon-black_rba7tb.svg" alt='Logo' loading='lazy' decoding='async' />
          </Navbar.Brand>
          <Navbar.Toggle onClick={toggleShow} >
            <img className='hamburguesa' width={45} height={45} src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1671552057/WALLET%20BLACK/button-burger_u8brmd.svg" alt='burger' loading='lazy' decoding='async' />
          </Navbar.Toggle>
          <Navbar.Offcanvas
           placement="top"    
           show={show}
           onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='mobile-navbar-container'>
              <Nav className="justify-content-end flex-grow-1 pe-3 container-menu-mobile text-[1em]">
                {vistaMobile && <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'items-navbar active' : 'items-navbar')} to="/">INICIO</NavLink>}
                <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'items-navbar active' : 'items-navbar')} to="/nosotros">NOSOTROS</NavLink>
                {/* <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'active' : 'items-navbar')} to="/tarjetas">TARJETAS</NavLink> */}
                <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'items-navbar active' : 'items-navbar')} to="/comisiones">COMISIONES</NavLink>
                <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'items-navbar active' : 'items-navbar')} to="/beneficios">BENEFICIOS</NavLink>
                <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'items-navbar active' : 'items-navbar')} to="/preguntas-frecuentes">PREGUNTAS FRECUENTES</NavLink>
                {/* <NavLink onClick={ScrollToTop} className={({ isActive }) => (isActive ? 'active' : 'items-navbar')} to="/robo-o-extravio">ROBO O EXTRAVÍO</NavLink> */}
                <NavLink onClick={()=>{ScrollToTop();handleClose()}} className={({ isActive }) => (isActive ? 'items-navbar active button-comercio-active' : 'items-navbar button-comercio')} to="/comerciantes"> COMERCIANTES </NavLink>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
