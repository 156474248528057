import { Swiper, SwiperSlide } from "swiper/react";

const Bebidas = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-beneficios-con-logos">
        <div className="slider-container-beneficios">
          <p className="titulo-slider-beneficio mb-4">Bebidas</p>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1500: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
            }}
            slidesPerView={6}
            spaceBetween={0}
          >
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1696864454/WALLET%20BLACK/Beneficios/doe4_ucyqss.svg" alt="doe" loading="lazy" decoding="async" width={154} height={66} />
              </div>
              <h5 className="titulo-comentario-cliente ">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                Sucursal BAXAR MERCADO AV. 51 E/5 Y 6
              </p>
            </SwiperSlide>

            {/* <SwiperSlide className="swipper-beneficios-con-logo">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1696863856/WALLET%20BLACK/Beneficios/cru_winebar-01_aiaxpe.svg" />
                </div>
                <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
                <p className="se:text-[10px] spro:text-[12px]">
                  Av. 1 1100 La Plata
                </p>
              </SwiperSlide> */}

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1696863857/WALLET%20BLACK/Beneficios/temple_bquk7z.svg" alt="temple" loading="lazy" decoding="async" width={74} height={86} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                DIAGONAL 74 Y 10 WWW.TEMPLE.COM.AR
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </article>
  );
};

export default Bebidas;
