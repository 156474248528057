import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {
  return (
    <section className="nosotros-faq">
      <div className="container-preguntas-black">
        <div className="child-container-preguntas-black">
          <Accordion defaultActiveKey="0">
            <Accordion.Item className="itemAsk" eventKey="01">
              <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                ¿Qué es y cómo funciona?
              </Accordion.Header>
              <Accordion.Body className="askFrecuentBody font-[400]">
                <p className="text-tab-desc font-[400]">
                  Black Wallet es tu billetera digital, que te permite hacer
                  múltiples operaciones financieras aunque no tengas cuenta en
                  un banco. Con tu Black Wallet hacés todo desde tu dispositivo
                  móvil, sin salir de tu casa y sin hacer filas.
                  <br></br>
                  <br></br>
                  Realizá todas las transacciones que quieras con tu saldo
                  disponible en Black. Y además:
                  <br></br>
                  <br></br>
                </p>
                <ul className='list-disc px-6'>
                    <li>
                      Ingresá dinero a tu cuenta para realizar todos los pagos
                      que quieras.
                    </li>
                    <li>
                      Realizá transferencias a otros usuarios Black o a cuentas
                      de otros bancos.
                    </li>
                    <li>Comprá todo eso que tanto deseas. </li>
                    <li>Retirá efectivo en distintos puntos de Pago Fácil. </li>
                    <li>
                      ¡Recibí tu Tarjeta <b>Black</b>!
                    </li>
                    <li>Y muchas cosas más</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="itemAsk" eventKey="02">
              <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                ¿Cómo creo mi alias Black?
              </Accordion.Header>
              <Accordion.Body className="askFrecuentBody ">
                <p className="text-tab-desc font-[400]">
                  Desde Home ingresá al Menú principal, y en la sección Tu
                  Información vas a poder crear tu alias como quieras. Tené en
                  cuenta que si ese alias ya existe, la app te pedirá que crees
                  otro.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item className="itemAsk" eventKey="03">
              <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                ¿Cómo puedo ingresar dinero desde mi cuenta bancaria?
              </Accordion.Header>
              <Accordion.Body className="askFrecuentBody font-[400]">
                Podés transferir desde tu cuenta bancaria a tu CVU y/o a tu
                Alias Black. Podés agendar en tu Home Banking el CVU de tu Black
                Wallet como destinatario frecuente y transferir dinero cuando
                quieras, de forma segura y rápida.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
