import { Swiper, SwiperSlide } from "swiper/react";

const Gastronomia = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-beneficios-con-logos">
        <div className="slider-container-beneficios">
          <p className="titulo-slider-beneficio mb-4">
            Gastronomía y comida rápida
          </p>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4.6,
                spaceBetween: 30,
              },
              1500: {
                slidesPerView: 5.8,
                spaceBetween: 30,
              },
            }}
            slidesPerView={6}
            spaceBetween={0}
          >
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690473171/WALLET%20BLACK/Social%20Club/baxar_wfee6q.svg" alt="baxar" loading="lazy" decoding="async" width={132} height={57} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">AV. 51 E/5 Y 6 · BAXAR MERCADO</p>
            </SwiperSlide>

            {/* <SwiperSlide className="swipper-beneficios-con-logo">
                  <div className="box-image-logo-container">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690474368/WALLET%20BLACK/Beneficios/m55_aaci6d.svg" />
                  </div>
                  <h5 className="titulo-comentario-cliente">
                    10% off con Black
                  </h5>
                  <p className="text-[12px]">Av. 1 1100 La Plata</p>
                </SwiperSlide> */}

            {/* <SwiperSlide className="swipper-beneficios-con-logo">
                  <div className="box-image-logo-container mt-2">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1692978434/WALLET%20BLACK/Beneficios/comoHermanos_aqpois.svg" />
                  </div>
                  <h5 className="titulo-comentario-cliente">
                    10% off con Black
                  </h5>
                  <p className="text-[12px]">Av. 1 1100 La Plata</p>
                </SwiperSlide> */}

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690474368/WALLET%20BLACK/Beneficios/elportenio_1_ya73nu.svg" alt="portenio" loading="lazy" decoding="async" width={180} height={84} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">467 ESQ. 144</p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container mb-2">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690474368/WALLET%20BLACK/Beneficios/foodie_pe8qca.svg" alt="foddie" loading="lazy" decoding="async" width={126} height={59} />
              </div>
              <h5 className="titulo-comentario-cliente pt-1">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                AVENIDA 51 ESQ. 5
              </p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477688/WALLET%20BLACK/Beneficios/la_cabrera_i7jznu.svg" alt="cabrera" loading="lazy" decoding="async" width={101} height={77} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                AV. 51 E/5 Y 6 · BAXAR MERCADO
              </p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477688/WALLET%20BLACK/Beneficios/la_trattoria_1_j6j0vb.svg" alt="trattoria" loading="lazy" decoding="async" width={164} height={106} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="se:text-[9px] spro:text-[10px]">
                CALLE 47 Y DIAG. 74 CNO. CENTENARIO ESQ. 504
              </p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477688/WALLET%20BLACK/Beneficios/meatpacking_1Cards_Beneficios_phlpzh.svg" alt="meat" loading="lazy" decoding="async" width={173} height={58} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                AV. 51 E/5 Y 6 · BAXAR MERCADO
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477679/WALLET%20BLACK/Beneficios/Group_3_nd1hzl.svg" alt="nina" loading="lazy" decoding="async" width={121} height={55} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">CALLE 471 E/13B Y 13C</p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477678/WALLET%20BLACK/Beneficios/paesano_mangia_italia_1Cards_Beneficios_ockgii.svg" alt="paesano" loading="lazy" decoding="async" width={162} height={138} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                CALLE 13A ESQ. 473 BIS
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690478976/WALLET%20BLACK/Beneficios/Group_507_1_g4zonj.svg" alt="paesano cuore" loading="lazy" decoding="async" width={162} height={54} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="se:text-[10px] spro:text-[12px]">
                AV. 51 E/5 Y 6 · BAXAR MERCADO
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477678/WALLET%20BLACK/Beneficios/paesano_rosso_1Cards_Beneficios_fns4va.svg" alt="paesano rosso" loading="lazy" decoding="async" width={162} height={122} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">AVENIDA 51 ESQ. 4</p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690477679/WALLET%20BLACK/Beneficios/GroupCards_Beneficios_pto9ib.svg" alt="runnies" loading="lazy" decoding="async" width={164} height={32} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">CALLE 10 ESQ. 47 WWW.RUNNIES.COM.AR</p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1693400805/WALLET%20BLACK/Beneficios/sushi_pop_zwiuu5.svg" alt="sushi" loading="lazy" decoding="async" width={117} height={52} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">
                Diag. 74 1594, La Plata Cantilo 1066, City Bell{" "}
              </p>
            </SwiperSlide>

            {/* <SwiperSlide className="swipper-beneficios-con-logo">
                  <div className="box-image-logo-container">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690477680/WALLET%20BLACK/Beneficios/elcallejon_blanco_1_acpywr.svg" />
                  </div>
                  <h5 className="titulo-comentario-cliente">
                    10% off con Black
                  </h5>
                  <p className="text-[12px]">CALLE 10 E/ 46 Y 47 </p>
                </SwiperSlide> */}

            {/* <SwiperSlide className="swipper-beneficios-con-logo">
                  <div className="box-image-logo-container">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1701181169/WALLET%20BLACK/Beneficios/Group_gjsh6w.svg" />
                  </div>
                  <h5 className="titulo-comentario-cliente">
                    10% off con Black
                  </h5>
                  <p className="text-[12px]">AV. 44 ESQUINA 141 Nº602 </p>
                </SwiperSlide> */}

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700227030/WALLET%20BLACK/Beneficios/-_livin_la_pata_loca_1_urqt8j.svg" alt="livin" loading="lazy" decoding="async" width={99} height={77} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">Calle 1 y 161, La Plata</p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </article>
  );
};

export default Gastronomia;
