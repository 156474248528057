import { Swiper, SwiperSlide } from "swiper/react";

const Cafeteria = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-container-beneficios">
        <p className="titulo-slider-beneficio mb-4">Cafetería</p>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
          }}
          slidesPerView={6}
          spaceBetween={0}
        >
          <SwiperSlide className="swipper-beneficios-con-logo">
            <div className="box-image-logo-container">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690473653/WALLET%20BLACK/Beneficios/perla_b1ysrp.svg" alt="perla" loading="lazy" decoding="async" width={142} height={24} />
            </div>
            <h5 className="titulo-comentario-cliente ">Pagá con Black</h5>
            <p className="se:text-[10px] spro:text-[12px]">
              DIAG. JORGE BELL E/471 Y 472
            </p>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <div className="box-image-logo-container">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690480428/WALLET%20BLACK/Beneficios/masseR_va8qcw.svg" alt="masse cafe" loading="lazy" decoding="async" width={90} height={65} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="se:text-[10px] spro:text-[12px]">
              AV. 51 E/5 Y 6 · MASSE DU MARCHE
            </p>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo">
            <div className="box-image-logo-container">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690480428/WALLET%20BLACK/Beneficios/masse_peovn0.svg" alt="masse" loading="lazy" decoding="async" width={140} height={32} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="se:text-[10px] spro:text-[12px]">
              DIAGONAL 74 ESQ. 10
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </article>
  );
};

export default Cafeteria;
