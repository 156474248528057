import { Accordion } from 'react-bootstrap';

const Preguntas = () => {
  return (
    <section className="beneficios-preguntas">
      <div className="container-preguntas-black">
        <div className="child-container-preguntas-black">
          <Accordion defaultActiveKey="0">
            <Accordion.Item className="itemAsk" eventKey="21">
              <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                Link de Pago
              </Accordion.Header>
              <Accordion.Body className="askFrecuentBody font-[400]">
                <p className="text-tab-desc font-[400]">
                  <b>¿Cómo realizo un pago mediante link?</b> <br></br>
                  ¡Realizá el pago de forma ágil y segura! Primero ingresá al
                  link de pago que te enviaron. Después elegí el medio de pago,
                  chequeá los datos y confirmá la operación ¡Listo!
                </p>
                <br></br>

                <p className="text-tab-desc font-[400]">
                  <b>¿Qué medios de pago puedo usar?</b> <br></br>
                  ¡Podés realizar los pagos como quieras! Dinero en Wallet,
                  tarjetas de crédito, tarjetas de débito y nuestra Tarjeta
                  Black.
                </p>
                <br></br>

                <p className="text-tab-desc">
                  <b>
                    ¿Cómo puedo comprobar que realicé el pago a través del link?
                  </b>{" "}
                  <br></br>
                  Podés confirmar tu pago con el comprobante que se genera al
                  realizar cada operación; también podés compartirlo a quien
                  necesites y descargarlo en tu celular. El movimiento queda
                  registrado en la sección Tu Actividad.
                </p>
                <br></br>

                <p className="text-tab-desc">
                  <b>¿Cómo cancelo una compra con Link de pago?</b> <br></br>
                  Si necesitás cancelar una compra contactate con el vendedor
                  para coordinar la devolución del dinero y del producto que
                  solicitaste.
                </p>
                <br></br>

                <p className="text-tab-desc">
                  <b>¿Cuánto tiempo puede demorar mi pago en ser procesado?</b>{" "}
                  <br></br>
                  Tené en cuenta que el tiempo depende según el medio de pago
                  utilizado, puede demorar hasta 2 días hábiles.
                </p>
                <br></br>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="itemAsk" eventKey="22">
              <Accordion.Header className="askFrecuentHeader font-[400] text-[2em] ">
                Código QR
              </Accordion.Header>
              <Accordion.Body className="askFrecuentBody font-[400]">
                <p className="text-tab-desc">
                  <b>¿Cómo realizo un pago con QR Black?</b> <br></br>
                  Para pagar con tu QR Black solo necesitás tener instalada la
                  app en tu celular y escanear el QR del vendedor! Una vez que
                  escanees el QR, tenés que ingresar el monto que desees pagar y
                  seleccionar el medio de pago. Para finalizar, chequeá si todos
                  los datos son correctos y confirmá la operación. Listo! Ahora
                  sí podés pagar con tu QR Black!
                </p>
                <br></br>

                <p className="text-tab-desc">
                  <b>¿Qué medios de pago puedo usar?</b> <br></br>
                  ¡Podés realizar los pagos como quieras! Con el dinero
                  disponible en tu wallet y/o con tus tarjetas <b>Black</b> (
                  tanto la virtual como la física)
                </p>
                <br></br>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="itemAsk" eventKey="23">
              <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                Pagos con Tarjeta Black
              </Accordion.Header>
              <Accordion.Body className="askFrecuentBody font-[400] space-y-4">
                <p className="text-tab-desc mb-4">
                  <b>¿Qué es la tarjeta de débito digital Black?</b> <br></br>
                  Descubrí tu nueva tarjeta de débito gratuita. Al momento de
                  activar tu cuenta Black, instantáneamente vas a tener una
                  versión digital para que puedas realizar compras online.
                  Luego, te va a llegar a tu casa tu tarjeta física para que la
                  uses en cualquier parte del mundo.
                </p>

                <p className="text-tab-desc">
                  <b> ¿Qué es la tarjeta de débito física Black?</b> <br></br>
                  Es la tarjeta de débito gratuita asociada a tu cuenta, la cual
                  te sirve para realizar todos los consumos que desees, donde
                  desees.
                </p>

                <p className="text-tab-desc">
                  <b>¿Cómo tener dinero para realizar consumos?</b> <br></br>
                  El saldo disponible en tu wallet se verá reflejado en tus
                  tarjetas digital y física.
                </p>

                <p className="text-tab-desc">
                  <b>Mis tarjetas Black no funcionan para comprar </b> <br></br>
                  Podés utilizarla desde el primer día en cualquier negocio o
                  tienda online que acepte Visa. Si no funciona corroborá que tu
                  Tarjeta Black no esté pausada desde la app, vencida y/o dañada
                  de manera física. En caso de daño y/o que esté vencida, te
                  enviaremos una nueva. Sí con esta información, no pudiste
                  resolver el inconveniente, recordá que podes contactarte con
                  nosotros a través de la opción “Chatear con un asesor” que se
                  encuentra al final de la sección Ayuda. También podés
                  contactarte mediante{" "}
                  <a
                    href="http://wa.link/mdys6n"
                    className="border-b ml-1 mr-1 border-white hover:border-transparent transition-all"
                    target="blank"
                  >
                    WhatsApp{" "}
                  </a>{" "}
                  o enviarnos un mail a info@blackwallet.com.ar
                </p>

                <p className="text-tab-desc ">
                  <b>¿Mi pago puede ser rechazado?</b> <br></br>
                  Los motivos de rechazo pueden deberse a:
                </p>
                <ul className="list-disc px-6">
                  <li>
                    Irregularidades en las validaciones del servicio
                    seleccionado o producto adquirido, el medio de pago u otros.
                  </li>
                  <li>
                    El medio de pago elegido está suspendido o bloqueado, o en
                    caso de que sea tarjeta se puede encontrar vencida.
                  </li>
                  <li>
                    No contar con el límite solicitado para la operación, o con
                    saldo disponible en tu <b>Black Wallet.</b>
                  </li>
                  <li>
                    Los datos de la operación no se informaron correctamente.
                  </li>
                  <li>
                    La entidad bancaria rechazó el pago por cuestiones propias
                    de seguridad.
                  </li>
                </ul>

                <p className="text-tab-desc">
                  <b>¿Dónde puedo utilizar mi Tarjeta Black?</b> <br></br>
                  La podés usar en cualquier comercio y/o sitio web del mundo,
                  que acepten VISA como medio de pago. Usá tu{" "}
                  <b>Tarjeta Black </b>
                  para comprar en el comercio que quieras o pagar los servicios
                  que precises, pagá la cena en un restaurante o las entradas
                  del cine, suscribite a servicios digitales y mucho más!
                </p>

                <p className="text-tab-desc">
                  <b>
                    ¿Por qué los consumos en moneda extranjera son más caros?
                  </b>{" "}
                  <br></br>
                  Según el decreto 354/2018, se aplica el 21% de IVA (Impuesto
                  al Valor Agregado) en las compras digitales de origen
                  extranjero, más el 8% o el 30% del Impuesto PAÍS a través del
                  Decreto 99/2019 y 45% en concepto de Percepción RG4815. Si vas
                  a suscribirte, por ejemplo, a servicios digitales necesitás
                  tener saldo suficiente para abonar el importe de la
                  suscripción más el 21%, el 8% de ese valor y el 45%.
                </p>

                <p className="text-tab-desc">
                  <b>¿Cómo obtengo el comprobante de mi pago?</b> <br></br>
                  Cuando realizás un pago exitoso, se genera de forma inmediata
                  un comprobante que podés compartir a quién necesités o
                  descargar en tu celular.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Preguntas;
