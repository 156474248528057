import { Nav, Tab } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import { useMediaQuery } from "react-responsive";
import PdfComerciantes from "./Black - Brochure Comercial.pdf";
import { Helmet } from "react-helmet";

import "./comerciantes.css";

// Import sections
import Hero from "./Hero";
import PYME from "./PYME";
import Colaboradores from "./Colaboradores";
import Preguntas from "./Preguntas";

export default function Comerciantes() {
  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <main className="comerciantes">
      <Helmet>
        <title>Comerciantes - Black Wallet</title>
        <meta
          name="description"
          content="Mire lo que Black ofrece a los comerciantes"
        />
      </Helmet>

      <animated.div style={fadeOut} className="comerciantes-container">
        {/* LA OPORTUNIDAD NEGOCIO */}
        {/* LA OPORTUNIDAD NEGOCIO */}
        <Hero />

        {vistaMobile ? (
          <Tab.Container defaultActiveKey="first">
            <div className="container-nav-comerciante font-ubuntu">
              <Nav className="container-navbar-comerciantes font-ubuntu">
                <Nav.Link
                  className="item-tab-menu font-ubuntu"
                  eventKey="first"
                >
                  {" "}
                  PYME{" "}
                </Nav.Link>
                <Nav.Link
                  className="item-tab-menu font-ubuntu"
                  eventKey="second"
                >
                  {" "}
                  Colaboradores{" "}
                </Nav.Link>
              </Nav>
            </div>
            <Tab.Content
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="1200"
            >
              {/* PYME */}
              {/* PYME */}
              <Tab.Pane eventKey="first">
                <PYME />
              </Tab.Pane>

              {/* PARA TUS COLABORADORES */}
              {/* PARA TUS COLABORADORES */}
              <Tab.Pane eventKey="second">
                <Colaboradores />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <>
            <PYME />
            <Colaboradores />
          </>
        )}

        <div className="container-flex-row tam-section-xl section-descargar-bouncher">
          <a
            className="container-button"
            target="_blank"
            href={PdfComerciantes}
            rel="noreferrer noopener"
          >
            {" "}
            Descargar brochure de beneficios{" "}
          </a>
        </div>

        <div className="bloque-separate">
          {/* se puede volar el bloque cuando quieras  */}
        </div>

        {/* PREGUNTAS */}
        <Preguntas />
      </animated.div>
    </main>
  );
}
