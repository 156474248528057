import { Swiper, SwiperSlide } from "swiper/react";

const Salud = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-beneficios-con-logos mt-16 sm:mt-24">
        <div className="slider-container-beneficios">
          <p className="titulo-slider-beneficio mb-4">
            Cuidado Pesonal y salud
          </p>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 4.6,
                spaceBetween: 30,
              },
              1500: {
                slidesPerView: 5.8,
                spaceBetween: 30,
              },
            }}
            slidesPerView={6}
            spaceBetween={0}
          >
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1693419014/WALLET%20BLACK/Beneficios/bienestar_ganolz.svg" alt="bienestar" loading="lazy" decoding="async" width={162} height={70} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">10% off con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                En farmacias y perfumerías de Bienestar
              </p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693419013/WALLET%20BLACK/Beneficios/ladoce_vyjqlz.svg" alt="la doce" loading="lazy" decoding="async" width={162} height={70} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">10% off con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                12 e/ 59 y 60 1307, La Plata
              </p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1693419014/WALLET%20BLACK/Beneficios/laInglesa_vzirov.svg" alt="la inglesa" loading="lazy" decoding="async" width={162} height={70} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">10% off con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                47 e/ 7 y 8 634, La Plata
              </p>
            </SwiperSlide>
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1693419013/WALLET%20BLACK/Beneficios/Zorich_wxlgpa.svg" alt="zorich" loading="lazy" decoding="async" width={162} height={70} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">10% off con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                7 esq. 54 1052, La Plata{" "}
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1693419013/WALLET%20BLACK/Beneficios/caruso_y22lim.svg" alt="caruso" loading="lazy" decoding="async" width={162} height={70} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">10% off con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                6 esq. 44 549, La Plata
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700227671/WALLET%20BLACK/Beneficios/-_farmacia_la_botica_del_rossi_1_dxesw6.svg" alt="botica" loading="lazy" decoding="async" width={140} height={49} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black </h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Calle 116 251 esquina 37, La Plata
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700227876/WALLET%20BLACK/Beneficios/-_farmacia_quantin_1_hy4xbl.svg" alt="quantin" loading="lazy" decoding="async" width={159} height={47} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black </h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Calle 27 739, La Plata
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700227938/WALLET%20BLACK/Beneficios/-_die_republik_der_brillen_1_zh5ys6.svg" alt="die" loading="lazy" decoding="async" width={75} height={75} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black </h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Calle 45 565, La Plata
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700228018/WALLET%20BLACK/Beneficios/-_Farmacia_avenida_1_ndczlr.svg" alt="avenida 1" loading="lazy" decoding="async" width={79} height={79} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Av. Santamarina 512, Tandil
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <div className="box-image-logo-container">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700228096/WALLET%20BLACK/Beneficios/-_Glam_1_tqprij.svg" alt="glam" loading="lazy" decoding="async" width={78} height={86} />
                </div>
              </div>
              <h5 className="titulo-comentario-cliente">15% off con Black</h5>
              <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
                Calle 136 1169 56 y 57, La Plata
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </article>
  );
};

export default Salud;
