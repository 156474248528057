import { useMediaQuery } from "react-responsive";
import { LazyVideo } from "../../Components/Lozad/Lozad";

const HaciaDondeVamos = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <section className="nosotros-haciadondevamos">
      <div className="video-timeline-container">
        <div className="container-flex-row gradient-section">
          <div className="child-container-flex-row">
            <div className="child-container-text">
              <h1 className="tittle-web enQueCreemos">
                ¿Hacia dónde <br /> vamos?
              </h1>
            </div>
            <div className="child-container-media"> </div>
          </div>
        </div>
        <div className="container-flex-row tam-section-lg gradient-section">
          <div className="child-container-flex-column video-timeline-secction">
            <div className="child-container-text"> </div>
            {vistaMobile ? (
              <div
                className={"child-container-media-video-mobile"}
              >
                <LazyVideo
                  src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675883561/WALLET%20BLACK/Videos/Hacia_Donde_Vamos_Mobile_q0c8wn.mp4"}
                  type={'video/mp4'}
                  poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1676037110/WALLET%20BLACK/Videos/Posters/hacia_donde_vamos_mobile_r2t7uw.png"}
                  width={375}
                  height={500}
                />
              </div>
            ) : (
              <div className="child-container-media-video">
                <video autoPlay muted loop>
                  <source src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1672075059/WALLET%20BLACK/hacia_donde_vamos__hxoakg.webm"></source>
                </video>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HaciaDondeVamos;
