import { Swiper, SwiperSlide } from "swiper/react";

const Indumentaria = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-container-beneficios">
        <p className="titulo-slider-beneficio mb-4">Indumentaria</p>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          slidesPerView={5}
          spaceBetween={0}
        >
          <SwiperSlide className="swipper-beneficios-con-logo md:max-w-[15%]">
            <div className="box-image-logo-container ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700230361/WALLET%20BLACK/Beneficios/-_akelarre_by_alessia_1_jart7z.svg" alt="akelarre" loading="lazy" decoding="async" width={185} height={36} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
              Calle 46 N° 712 e/ 9 y 10,La Plata
            </p>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo md:max-w-[15%]">
            <div className="box-image-logo-container ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700230444/WALLET%20BLACK/Beneficios/logo-albert-sport_1_it0jzz.svg" alt="albert" loading="lazy" decoding="async" width={86} height={85} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
              Calle 12 1265 La Plata Av. 60 2086 Los Hornos
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </article>
  );
};

export default Indumentaria;
