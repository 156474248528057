import { Swiper, SwiperSlide } from "swiper/react";

const Regalerias = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-container-beneficios">
        <p className="titulo-slider-beneficio mb-4">
          Regalerías, Jugueterías y Bazar
        </p>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          slidesPerView={5}
          spaceBetween={0}
        >
          <SwiperSlide className="swipper-beneficios-con-logo md:max-w-[15%]">
            <div className="box-image-logo-container ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700229939/WALLET%20BLACK/Beneficios/-_el_castillo_1_zbvxmp.svg" alt="castillo" loading="lazy" decoding="async" width={76} height={83} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
              Au Dr. Ricardo Balbín Km 9 Sarandí, Parque Comercial Avellaneda
            </p>
          </SwiperSlide>

          <SwiperSlide className="swipper-beneficios-con-logo md:max-w-[15%]">
            <div className="box-image-logo-container ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700230004/WALLET%20BLACK/Beneficios/Capa_1_1_tu0icb.svg" alt="luichi" loading="lazy" decoding="async" width={105} height={41} />
            </div>
            <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
            <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
              Au Dr. Ricardo Balbín Km 9 Sarandí, Parque Comercial Avellaneda
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </article>
  );
};

export default Regalerias;
