import { useSpring, animated } from "react-spring";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import politicas from "./politicas.pdf";
import terminos from "./Terminos.pdf";

import "./preguntasFrecuentes.css";
import "swiper/css/effect-creative";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export default function Preguntas() {
  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  return (
    <main className="preguntas">
      <Helmet>
          <title>Preguntas Frecuentes - Black Wallet</title>
          <meta name="description" content="Si tienes preguntas, nosotros te damos las respuestas" />
      </Helmet>

      <animated.div style={fadeOut}>
        <div className="preguntas-frecuentes-container">
          {vistaMobile === false ? (
            <div className="bloque-separate">
              {/* se puede volar el bloque cuando quieras  */}
            </div>
          ) : null}

          {vistaMobile === false ? (
            <Desktop />
          ) : (
            <Mobile />
          )}

          <div className="bloque-descargas">
            <a href={terminos} className="bloque-descarga-box" target="_blank" rel="noopener noreferrer">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/v1672405280/WALLET%20BLACK/Frame_428_5_mynyoe.svg" alt="download" loading="lazy" decoding="async" width={50} height={51} />

              <div>
                <p className="titulo-bloque-descarga-box">
                  Términos y condiciones de uso
                </p>
                <p className="descripcion-bloque-descarga-box">
                  Descargar archivo
                </p>
              </div>
            </a>

            <a className="bloque-descarga-box" href={politicas} target="_blank" rel="noopener noreferrer">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/v1672405280/WALLET%20BLACK/Frame_428_5_mynyoe.svg" alt="download" loading="lazy" decoding="async" width={50} height={51} />

              <div>
                <p className="titulo-bloque-descarga-box">
                  Políticas de privacidad
                </p>
                <p className="descripcion-bloque-descarga-box">
                  Descargar archivo
                </p>
              </div>
            </a>

            {/* <div className={verTodos && vistaMobile ? "bloque-descarga-box bloque-descarga-box-with-transparency" : "bloque-descarga-box"}>
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672405280/WALLET%20BLACK/Frame_428_5_mynyoe.svg" />
              <div>
                <p className="titulo-bloque-descarga-box">Tarjeta Black</p>
                <p className="descripcion-bloque-descarga-box">Descargar términos y condiciones</p>
              </div>
            </div> */}
          </div>
        </div>
      </animated.div>
    </main>
  );
}
