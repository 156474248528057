import { useMediaQuery } from "react-responsive";
import { LazyVideo } from "../../Components/Lozad/Lozad";

const AhoraPodes = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 787px)" });

  return (
    <section className="home-ahorapodes">
      <div className="secction-text-background-video flex font-ubuntu flex-col xs:mb-24 md:mb-0">
        <div data-aos={vistaMobile ? null : "zoom-in-up"}>
          <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1678196451/WALLET%20BLACK/celu_y_tarjeta_2_julokh.png" alt="Celu Tarjeta" loading="lazy" decoding="async" width={550} height={664} />
        </div>
        {!vistaMobile ? (
          <video
            poster="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1678199092/WALLET%20BLACK/Videos/Posters/web_home_fondo_de_cel_y_tarjeta_2_kvtwgk.png"
            muted
            loop
            autoPlay
            src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1678194830/WALLET%20BLACK/Videos/web_home_fondo_de_cel_y_tarjeta_joj9hl.mp4"
            style={{
              backgroundColor: "#000",
              position: "absolute",
              bottom: "0",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: "-999",
            }}
          ></video>
        ) : (
            <div
              className={"video-container-background-home"}
              style={{
                backgroundColor: "#000",
                position: "absolute",
                bottom: "0",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                zIndex: "-999",
              }}
            >
              <LazyVideo
                src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1678197890/WALLET%20BLACK/Videos/web_home_mobile_fondo_tarjeta_y_cel_nvcujs.mp4"
                type="video/mp4"
                poster="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1678197918/WALLET%20BLACK/Videos/Posters/web_home_mobile_fondo_tarjeta_y_cel_ycrkoy.png"
                width={640}
                height={360}
              />
            </div>
        )}
        <h1 className="font-bold text-[30px] xs:text-[50px] text-white text-center">
          Una nueva forma<br/>
          de administrar tu plata
        </h1>
        <h5 className="text-white text-center xs:text-[17px] mt-2">
          Comprá, cobrá, enviá y recibí dinero desde tu wallet.
        </h5>
      </div>
    </section>
  );
};

export default AhoraPodes;
