import { Swiper, SwiperSlide } from "swiper/react";

const Construccion = () => {
  return (
    <article className="beneficios-convenio">
      <div className="slider-container-beneficios">
        <p className="titulo-slider-beneficio mb-4">Construcción & Deco</p>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          slidesPerView={5}
          spaceBetween={0}
        >
          <SwiperSlide className="swipper-beneficios-con-logo md:max-w-[15%]">
            <div className="box-image-logo-container ">
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690482707/WALLET%20BLACK/Beneficios/Group_7_ztprfb.svg" alt="sp" loading="lazy" decoding="async" width={58} height={86} />
            </div>
            <h5 className="titulo-comentario-cliente">Hasta - 10%</h5>
            <p className="descripcion-comentario-cliente se:text-[10px] spro:text-[12px]">
              Av 66 2252 La Plata
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </article>
  );
};

export default Construccion;
