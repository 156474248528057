import { LazyVideo } from "../../Components/Lozad/Lozad";

const Seguridad = () => {
  return (
    <section className="home-seguridad">
      <div className="container-flex-row tam-margin-xl font-ubuntu home-segurity-block">
        <div className="child-container-flex-row">
          <div
            className={"child-container-media"}
          >
            <LazyVideo
              src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675877701/WALLET%20BLACK/Videos/Web_Pin_Mobile_nxwxke.mp4"}
              type={'video/mp4'}
              poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1676036397/WALLET%20BLACK/Videos/Posters/web_pin_mobile_whjvdl.png"}
              width={450}
              height={450}
            />
          </div>
          <div className="child-container-text">
            <h1 className="tittle-web">
              Tu seguridad es <br></br> lo más importante
            </h1>
            <p className="font-ubuntu text-white text-[1.2em] mb-2">
              Operá con tranquilidad, nosotros nos encargamos de la seguridad de
              tus datos.
            </p>

            <div className="container-flex">
              <div className="container-card-flex">
                <div className="card-image">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672252906/WALLET%20BLACK/eyeIcon_rxvkjd.png" alt="personal" loading="lazy" decoding="async" width={37} height={34} />
                </div>
                <div className="card-text">
                  <h5 className="tittle-card-security">Personal</h5>
                  <p>Un dispositivo a la vez y se cierra automáticamente</p>
                </div>
              </div>

              <div className="container-card-flex">
                <div className="card-image">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672312789/WALLET%20BLACK/candado_xwthlu.png" alt="pin" loading="lazy" decoding="async" width={28} height={37} />
                </div>
                <div className="card-text">
                  <h5 className="tittle-card-security">Código PIN</h5>
                  <p>Solicitud de PIN para las operaciones</p>
                </div>
              </div>

              <div className="container-card-flex">
                <div className="card-image">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672312789/WALLET%20BLACK/huellita_i0gbgm.png" alt="Biometría" loading="lazy" decoding="async" width={36} height={36} />
                </div>
                <div className="card-text">
                  <h5 className="tittle-card-security">Biometría</h5>
                  <p>
                    Podes acceder a tu wallet con reconocimiento facial o con tu
                    huella digital
                  </p>
                </div>
              </div>

              <div className="container-card-flex">
                <div className="card-image">
                  <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672312789/WALLET%20BLACK/encriptacion_lp9on8.png" alt="Encriptación" loading="lazy" decoding="async" width={36} height={36} />
                </div>
                <div className="card-text">
                  <h5 className="tittle-card-security">Encriptación</h5>
                  <p>
                    Protegemos la confidencialidad y seguridad de tus datos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Seguridad;
