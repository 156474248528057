import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";

const SumateBlack = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 787px)" });

  return (
    <section className="home-sumateblack">
      <div className="container-sumate-black">
        <div
          className="child-sumate-black font-ubuntu"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <div className="flex flex-col xs:flex-row items-center">
            <h5 className="mb-2 font-[500] xs:mr-1"> Sumate a </h5>
            <img
              className="icon-black-top se:mb-4 xs:mb-0"
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672080908/WALLET%20BLACK/BLACKK_lvmc62.png"
              alt="Black"
              loading="lazy"
              decoding="async"
              width={180}
              height={43}
            />
          </div>

          <p>Comenzá a disfrutar todos los beneficios que tenemos para vos</p>
        </div>
        {!vistaMobile ? (
          <>
            <div className="container-flex-row font-ubuntu">
              <div className="cards-suma-black">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672071795/WALLET%20BLACK/circulo_flecha_abajo_pvgbre.svg" alt="Download" loading="lazy" decoding="async" width={45} height={45} />
                <h5 className="font-[500] mb-1 text-[1.2em]">
                  Descargá la App
                </h5>
                <p>Descargá Black Wallet en tu smartphone</p>
              </div>

              <div className="line-separate-cards"></div>

              <div className="cards-suma-black">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672399597/WALLET%20BLACK/userr_awrrqf.png" alt="Filled Out" loading="lazy" decoding="async" width={45} height={45} />
                <h5 className="font-[500] mb-1 text-[1.2em]">
                  Completá tus datos
                </h5>
                <p>En pocos pasos estará listo tu perfil</p>
              </div>

              <div className="line-separate-cards"></div>

              <div className="cards-suma-black">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672399597/WALLET%20BLACK/checkk_xd1b6g.png" alt="Check ID" loading="lazy" decoding="async" width={45} height={45} />
                <h5 className="font-[500] mb-1 text-[1.2em]">
                  Verificá tu identidad
                </h5>
                <p>
                  Trabajamos con Renaper, el registro nacional de las personas
                  para una mayor seguridad.
                </p>
              </div>

              <div className="line-separate-cards"></div>

              <div className="cards-suma-black">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672070919/WALLET%20BLACK/circulo_venuscirculo_venus_w21goc.png" alt="You're Black" loading="lazy" decoding="async" width={45} height={45} />
                <h5 className="font-[500] mb-1 text-[1.2em]">Ya sos Black!</h5>
                <p>Vas a recibir la confirmación por notificación y mail</p>
              </div>
            </div>

            <div className="scroll-seccion-botones">
              <a
                id="playstore"
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=app.blackwallet.ar"
              >
                <button id="playstore">
                  <img
                    style={{ height: "35px", objectFit: "contain" }}
                    alt="google play"
                    src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672685993/WALLET%20BLACK/play_store-01_wdahsu.svg"
                    loading="lazy"
                    decoding="async"
                    width={40}
                    height={35}
                  />
                  <div>
                    <p className="button-scroll-sub">Descargala en</p>
                    <p
                      style={{ fontSize: "22px" }}
                      className="button-scroll-titulo"
                    >
                      Google Play
                    </p>
                  </div>
                </button>
              </a>

              <a
                target="_blank"
                id="appstore"
                rel="noreferrer"
                href="https://apps.apple.com/us/app/black-wallet/id1661314614"
              >
                <button id="appstore">
                  <img
                    alt="apple store"
                    src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672744949/WALLET%20BLACK/apple-logo-01-01_el9iip.svg"
                    loading="lazy"
                    decoding="async"
                    width={40}
                    height={43}
                  />
                  <div>
                    <p className="button-scroll-sub">Descargala en</p>
                    <p className="button-scroll-titulo">App Store</p>
                  </div>
                </button>
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="section-slider slider-home">
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  1500: {
                    slidesPerView: 2.5,
                    spaceBetween: 0,
                  },
                }}
                slidesPerView={2}
                spaceBetween={0}
                className="slider-fotos"
              >
                <SwiperSlide>
                  <div className="cards-suma-black">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672071795/WALLET%20BLACK/circulo_flecha_abajo_pvgbre.svg" alt="Download" loading="lazy" decoding="async" width={45} height={45} />
                    <h5 className="font-[500] mb-1 text-[1.2em]">
                      Descargá la App
                    </h5>
                    <p>Descargá Black Wallet en tu smartphone</p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="cards-suma-black">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672399597/WALLET%20BLACK/userr_awrrqf.png" alt="Filled Out" loading="lazy" decoding="async" width={45} height={45} />
                    <h5 className="font-[500] mb-1 text-[1.2em]">
                      Completá tus datos
                    </h5>
                    <p>En pocos pasos estará listo tu perfil</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="cards-suma-black">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672399597/WALLET%20BLACK/checkk_xd1b6g.png" alt="Check ID" loading="lazy" decoding="async" width={45} height={45} />
                    <h5 className="font-[500] mb-1 text-[1.2em]">
                      Verificá tu identidad
                    </h5>
                    <p>
                      Trabajamos con Renaper, el registro nacional de las
                      personas para una mayor seguridad.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="cards-suma-black">
                    <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672070919/WALLET%20BLACK/circulo_venuscirculo_venus_w21goc.png" alt="You're Black" loading="lazy" decoding="async" width={45} height={45} />
                    <h5 className="font-[500] mb-1 text-[1.2em]">
                      Ya sos Black!
                    </h5>
                    <p>Vas a recibir la confirmación por notificación y mail</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </>
        )}

        {vistaMobile ? (
          <div className="store-sumate">
            <a id="playstore" href="https://apps.apple.com/us/app/black-wallet/id1661314614" target="_blank" rel="noreferrer">
              {" "}
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1671803922/WALLET%20BLACK/googleplay_qhwv7l.png" alt="Play Store" loading="lazy" decoding="async" width={158} height={45} />{" "}
            </a>
            <a id="appstore" href="https://apps.apple.com/us/app/black-wallet/id1661314614" target="_blank" rel="noreferrer">
              {" "}
              <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1671803922/WALLET%20BLACK/appstore_teryy5.png" alt="Apple Store" loading="lazy" decoding="async" width={160} height={52} />{" "}
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default SumateBlack;