import { useMediaQuery } from "react-responsive";
import { LazyVideo } from "../../Components/Lozad/Lozad";

const AhoraPodes = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <section className="nosotros-ahorapodes">
      <div className="container-flex-row tam-section-lg gradient-section font-ubuntu">
        <div className="child-container-flex-row">
          <div className="child-container-text font-ubuntu text-[1.1em]">
            <h1 className="text-white font-bold font-ubuntu text-[4em]">
              Ahora sí podés.
            </h1>
            <p className="text-web font-[400]">
              Somos una fintech de alcance nacional que nació para brindarte
              oportunidades. Llegamos para demostrar que lo que creías
              inalcanzable, ahora es para vos.
            </p>
            <p className="text-web">
              Queremos que te sientas orgulloso de tener tu Black, así como
              nosotros también nos sentimos orgullosos de verte cumplir tus
              deseos.
            </p>
            <p className="text-web">Con Black, ahora sí podés.</p>
          </div>
          {vistaMobile ? (
            <div
              className={"child-container-media"}
            >
              <LazyVideo
                src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675884563/WALLET%20BLACK/Videos/Quienes_Somos_Animaciones_ac3pvh.mp4"}
                type={'video/mp4'}
                poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1676036804/WALLET%20BLACK/Videos/Posters/quienes_somos__mobile_effgg8.png"}
                width={500}
                height={500}
              />
            </div>
          ) : (
            <div className="child-container-media">
              <video width={750} autoPlay muted loop>
                <source src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1672839588/WALLET%20BLACK/quienes_somos_animaciones_1_gwnm8p.webm"></source>
              </video>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AhoraPodes;
