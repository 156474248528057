import { Tab, Row, Col, Nav, Accordion } from "react-bootstrap";

import miBlackWalletFaqs from './preguntas-json/mi-black-wallet.json';
import pagos from './preguntas-json/pagos.json';
import perfilComerciante from './preguntas-json/perfil-comerciante.json';
import tarjeta from './preguntas-json/tarjeta.json';
import seguridad from './preguntas-json/seguridad.json';
import perfil from './preguntas-json/perfil.json';
import tutoriales from './preguntas-json/tutoriales.json';

const Desktop = () => {
  return (
    <section className="preguntas-desktop">
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav
              variant="pills"
              className="flex-column"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="first">
                  Mi Black Wallet: <br></br> ¿Qué es y cómo funciona?
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="second">
                  Pagos: <br></br> Compras, pagos y suscripciones
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="third">
                  Perfil Comerciante: <br></br> ¿Cómo cobrar y recibir tu plata?
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="four">
                  Tarjeta Black
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="five">
                  Seguridad
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="six">
                  Perfil y datos
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link className="item-tab-menu" eventKey="seven">
                  Tutoriales
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <Tab.Pane eventKey="first">
                <Accordion defaultActiveKey="0" className="font-ubuntu">
                  {
                    miBlackWalletFaqs.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${miBlackWalletFaqs.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <Accordion defaultActiveKey="1">
                  {
                    pagos.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${pagos.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                <Accordion defaultActiveKey="2">
                  {
                    perfilComerciante.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${perfilComerciante.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>

              <Tab.Pane eventKey="four">
                <Accordion defaultActiveKey="3">
                  {
                    tarjeta.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${tarjeta.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>

              <Tab.Pane eventKey="five">
                <Accordion defaultActiveKey="4">
                  {
                    seguridad.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${seguridad.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>

              <Tab.Pane eventKey="six">
                <Accordion defaultActiveKey="5">
                  {
                    perfil.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${perfil.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>

              <Tab.Pane eventKey="seven">
                <Accordion defaultActiveKey="6">
                  {
                    tutoriales.faqs?.map(faq => {
                      return(<Accordion.Item key={faq.id} className="itemAsk" eventKey={`${tutoriales.eventKeyInitial}${faq.id}`}>
                        <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
                          {faq.header}
                        </Accordion.Header>
                        <Accordion.Body className="askFrecuentBody font-[400]" dangerouslySetInnerHTML={{__html: faq.content}} />
                      </Accordion.Item>)
                    })
                  }
                </Accordion>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </section>
  );
};

export default Desktop;