import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { LazyVideo } from "../../Components/Lozad/Lozad";

const PagaCuotas = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 787px)" });

  return (
    <section className="home-pagacuotas">
      <div className="container-flex-row tam-margin-xl bgGradient relative xs:w-[80%] lt:w-[67%] md:w-[64%] rounded-r-[8rem]">
        <div className="child-container-flex-row flex flex-row">
          <div className="space-y-5 md:ml-[20%] md:w-[60%] md:py-12">
            <div>
              <h5 className="font-bold text-[44px] text-white">
                Comprá donde quieras, convertite en Blacker
              </h5>
            </div>
            <p className="text-web w-[85%]">
              Descubrí tu nueva Visa Black. Una tarjeta de débito para que uses
              en cualquier parte del mundo.
            </p>
            <Link to="/comisiones" className="container-button">
              <button className="mt-4"> Conocé más</button>
            </Link>
          </div>
          {vistaMobile ? (
            <div
              className="child-container-media"
            >
              <LazyVideo
                src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675880947/WALLET%20BLACK/Videos/Tarjeta-Paga_En_Cuotas_b85l5r.mp4"}
                type={'video/mp4'}
                poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1676038421/WALLET%20BLACK/Videos/Posters/tarjeta-paga_en_cuotas_yns8x0.png"}
                width={500}
                height={500}
              />
            </div>
          ) : (
            <div className="absolute right-[-10%] top-[1%]">
              <video className="w-[400px]" autoPlay muted loop>
                <source src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1672839204/WALLET%20BLACK/tarjeta-paga_en_cuotas_1_vbo0tr.webm"></source>
              </video>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PagaCuotas;
