import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { LazyVideo } from "../../Components/Lozad/Lozad";

const ASoloCero = () => {
  const vistaMobile = useMediaQuery({ query: "(max-width: 787px)" });

  return (
    <section className="home-asolocero">
      <div className="hero-container-home">
        {vistaMobile ? (
          <div
            className={"video-container"}
          >
            <LazyVideo
              src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675878460/WALLET%20BLACK/Videos/Web_Comisiones_Mobile_poibwd.mp4"}
              type={'video/mp4'}
              poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1676036512/WALLET%20BLACK/Videos/Posters/web_comisiones_mobile_u6ll9o.png"}
              width={500}
              height={500}
              className="hero-video-background-home"
            />
          </div>
        ) : (
          <video
            autoPlay
            loop
            muted
            className="hero-video-background-home"
            src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1672840008/WALLET%20BLACK/web_comisiones_iot0av.webm"
          />
        )}
        <div className="flex items-center flex-col ">
          <h1 className="font-ubuntu text-white font-bold text-[3.3em]">
            A solo $0
          </h1>
          <h5 className="font-ubuntu text-[1.1em] text-center w-[75%] text-white mt-4 mb-4  font-[500]">
            Los mejores beneficios, sin los costos asociados a una cuenta
            bancaria
          </h5>
          <Link to="/comisiones" className="container-button">
            Conocé nuestras comisiones
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ASoloCero;
