import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Preguntas = () => {
  return (
    <section className="container-preguntas-black">
      <div className="child-container-preguntas-black">
        <Accordion defaultActiveKey="3">
          <Accordion.Item className="itemAsk" eventKey="30">
            <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
              ¿Cuál es el costo por usar el servicio?
            </Accordion.Header>
            <Accordion.Body className="askFrecuentBody font-[400]">
              <p className="text-tab-desc">
                El servicio no tiene costos mensuales. Solo vas a pagar el costo
                de la transacción en el caso de utilizarlo.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="itemAsk" eventKey="101">
            <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
              ¿Cómo darme de alta como comercio de Black?
            </Accordion.Header>
            <Accordion.Body className="askFrecuentBody font-[400]">
              <p className="text-tab-desc">
                Ingresá a{" "}
                <a
                  href="https://blackwallet.com.ar/comerciantes"
                  className="border-b ml-1 mr-1 border-white hover:border-transparent transition-all"
                  target="blank"
                >
                  https://blackwallet.com.ar/comerciantes
                </a>
                y completá el formulario. Vamos a verificar tus datos y dar de
                alta tu comercio. Te va a llegar una notificación mediante tu
                app de Black cuando tu perfil comercial esté listo. Desde ese
                momento vas a poder utilizar tus dos perfiles en la misma app y
                seguir los movimientos de tu negocio y crear sucursales desde{" "}
                <a
                  href="https://plataforma.blackwallet.com.ar/"
                  className="border-b ml-1 mr-1 border-white hover:border-transparent transition-all"
                  target="blank"
                >
                  https://plataforma.blackwallet.com.ar/{" "}
                </a>{" "}
                .
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="itemAsk" eventKey="102">
            <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
              ¿Qué es la plataforma Black?
            </Accordion.Header>
            <Accordion.Body className="askFrecuentBody font-[400]">
              <p className="text-tab-desc">
                Plataforma Black es la solución para administrar tu negocio, ver
                estadísticas, crear sucursales y asignar encargados de cobro,
                imprimir tu QR y afiches promocionales, todo desde un mismo
                lugar. Ingresá a{" "}
                <a
                  href="https://plataforma.blackwallet.com.ar/"
                  className="border-b ml-1 mr-1 border-white hover:border-transparent transition-all"
                  target="blank"
                >
                  https://plataforma.blackwallet.com.ar/{" "}
                </a>
                para conocerla.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="itemAsk" eventKey="31">
            <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
              Códigos QR
            </Accordion.Header>
            <Accordion.Body className="askFrecuentBody space-y-4 font-[400]">
              <p className="text-tab-desc">
                <b>¿Qué es y cómo se genera el QR?</b> <br></br>
                Es un código único que te permite cobrar sin utilizar efectivo
                ni tarjetas físicas. Una vez dado de alta tu comercio en Black
                se va a generar sin costo, y podrás cobrar por tus productos o
                servicios con él. Estará asociado a tu persona mediante tu ID de
                comerciante. .
              </p>

              <p className="text-tab-desc">
                <b>¿Cómo cobrar con QR?</b> <br></br>
                Ingresá a tu perfil comercial, y en la Home tocá el botón Ver mi
                QR, pedile a tu cliente que lo escanee. También podés utilizar
                los QR impresos suministrados por Black o imprimirlo tantas
                veces como quieras, desde el botón Imprimir mi QR. En todos los
                casos, tu código siempre será el mismo. El monto indicado será
                enviado a tu Black Wallet, deduciendo los aranceles según los
                plazos y tasas que hayas seleccionado.
              </p>

              <p className="text-tab-desc">
                <b>
                  ¿Tengo que estar registrado en la App de Black antes de cobrar
                  con QR?
                </b>{" "}
                <br></br>
                Es necesario que tengas la App de Black, estés registrado y seas
                mayor de 18 años, también podés registrar tu comercio mediante
                nuestra web{" "}
                <b>
                  <Link to="/comerciantes">
                    www.blackwallet.com.ar/comerciantes
                  </Link>
                </b>
                .
              </p>

              <p className="text-tab-desc">
                <b>¿Qué medios de pago puedo ofrecer a mis clientes?</b>{" "}
                <br></br>
                ¡Podés ofrecer distintas opciones de pago a tus clientes! Dinero
                en Wallet, tarjetas de crédito y débito <b>Black</b>.
              </p>

              <p className="text-tab-desc">
                <b>¿Cómo devuelvo un pago con QR?</b> <br></br>
                Si necesitás cancelar una compra contactate con tu cliente para
                coordinar la devolución del dinero y del producto que vendiste.
              </p>
              <br></br>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="itemAsk" eventKey="32">
            <Accordion.Header className="askFrecuentHeader font-[400] text-[2em]">
              Link de Pago
            </Accordion.Header>
            <Accordion.Body className="askFrecuentBody font-[400] space-y-4">
              <p className="text-tab-desc">
                <b>¿Qué es un link de pago?</b>
                <br></br>
                Es un método de cobro en línea con el que podés cobrar por tus
                productos y servicios, sin que tu cliente esté físicamente en tu
                comercio.
              </p>

              <p className="text-tab-desc">
                <b>¿Cómo cobrar con Link?</b>
                <br></br>
                Desde Cobrar, seleccioná Solicitar por link, ingresá el monto
                que deseas cobrar por tu producto o servicio, generá el link y
                compartirlo con quien quieras! El monto indicado será enviado a
                tu Black Wallet, deduciendo los aranceles según los plazos y
                tasas que hayas seleccionado.
              </p>

              <p className="text-tab-desc">
                <b>¿Qué medios de pago puedo ofrecer a mis clientes?</b>
                <br></br>
                ¡Podés ofrecer distintas opciones de pago a tus clientes! Dinero
                en Wallet, tarjetas <b>Black</b> (Física y digital)
              </p>

              <p className="text-tab-desc">
                <b>¿Por qué un pago puede ser rechazado?</b>
                <br></br>
                Todos los movimientos son verificados por <b>Black</b> para
                garantizar a sus usuarios que sean seguros. Los motivos de
                rechazo pueden deberse a: <br></br>
              </p>

              <ul className="list-disc px-6">
                <li>
                  Irregularidades en las validaciones del servicio seleccionado
                  o producto adquirido, el medio de pago u otros.
                </li>
                <li>
                  El medio de pago elegido está suspendido o bloqueado, o en
                  caso de que sea tarjeta se puede encontrar vencida.
                </li>
                <li>
                  No contar con el límite solicitado para la operación, o que tu
                  cliente no cuente con saldo disponible en su{" "}
                  <b>Black Wallet</b>.
                </li>
                <li>
                  Los datos de la operación se informaron incorrectamente.
                </li>
                <li>
                  La entidad bancaria rechazó el pago por cuestiones propias de
                  seguridad.
                </li>
              </ul>
              <br></br>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
};

export default Preguntas;
