import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Creemos = () => {
  return (
    <section className="nosotros-creemos">
      <div className="child-container-slide">
        <div className="slider-section-container" id="slider-home">
          <div
            className="slider-secction-text"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <h5 className="text-creemos">¿En qué creemos?</h5>
          </div>
          <div className="section-slider">
            <Swiper
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                1200: {
                  slidesPerView: 2.6,
                  spaceBetween: 0,
                  pagination: false,
                },
                1500: {
                  slidesPerView: 3.2,
                  spaceBetween: 0,
                  pagination: false,
                },
              }}
              pagination={true}
              modules={[Pagination]}
              slidesPerView={3.2}
              spaceBetween={0}
              className="slider-fotos"
            >
              <SwiperSlide>
                <div className="comentarios-clientes">
                  <p className="titulo-comentario-cliente">Transparencia</p>
                  <p className="descripcion-comentario-cliente">
                    Si las cuentas claras conservan la amistad, la información
                    clara hace que conserves tu Black. Queremos que tengas
                    control absoluto de tu finanzas, y que puedas tener acceso a
                    la información que necesites, antes de realizar cada
                    movimiento.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="comentarios-clientes">
                  <p className="titulo-comentario-cliente">Accesibilidad</p>
                  <p className="descripcion-comentario-cliente">
                    Black es para todos y de todos. Queremos que no solo puedas
                    tener tu dinero de forma digital, sino que además accedas a
                    tu primera tarjeta de crédito. De forma rápida y sencilla,
                    tu tarjeta Black no solo es la oportunidad que necesitabas,
                    sino que además es la que esperabas.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="comentarios-clientes">
                  <p className="titulo-comentario-cliente">Confianza</p>
                  <p className="descripcion-comentario-cliente">
                    En vos. Cuando los sistemas tradicionales te digan que no,
                    nosotros te vamos a decir que sí. <br></br>
                    ¿Por qué? Porque queremos darte la oportunidad de que vos
                    también confíes en vos mismo.
                    <br></br>
                    <br></br>
                    <b>En nuestro equipo.</b> El optimismo y las ganas de llegar
                    lejos es lo que nos trajo hasta acá y lo que nos impulsa a
                    ir por más.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="comentarios-clientes">
                  <p className="titulo-comentario-cliente">
                    Sentido de pertenencia
                  </p>
                  <p className="descripcion-comentario-cliente">
                    Queremos que quieras probar Black y que quieras quedarte
                    también. No solo por nuestros beneficios, sino también
                    porque confiás en que cada vez que necesites algo, vamos a
                    estar ahí para vos.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Creemos;
