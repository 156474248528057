import { useSpring, animated } from "react-spring";
import { Helmet } from "react-helmet";

import './nosotros.css';

// Import Sections
import AhoraPodes from './AhoraPodes';
import Creemos from './Creemos';
import HaciaDondeVamos from './HaciaDondeVamos';
// import SonBlack from './SonBlack';
import FAQ from "./FAQ";

export default function Nosotros() {

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  return (
    <main className="nosotros">
      <Helmet>
          <title>Nosotros - Black Wallet</title>
          <meta name="description" content="Información sobre Black Wallet" />
      </Helmet>

      <animated.div style={fadeOut}>
        <div className='container-nosotros'>
          <AhoraPodes />
          <Creemos />
          <HaciaDondeVamos />
          {/* <SonBlack /> */}
          <FAQ />
        </div>
      </animated.div>
    </main>
  )
}
