const Slider2 = () => {
  return (
    <section className="comisiones-slider2">
      <div className="container-comision-text">
        <h1 className="tittle-comision-web text-[35px] mb-2 font-bold">
          {" "}
          Solo vas a pagar por{" "}
        </h1>
      </div>
      <div className="comentarios-clientes-comision">
        <div className="child-container-comision pb-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672071356/WALLET%20BLACK/ciruclo_tarjeta_nmblmk.png"
                alt="Reemplazo"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div>
              <p className="titulo-comision"> Reemplazo de tarjeta </p>
              <p className="descripcion-comision">
                En caso de pérdida o rotura de tu tarjeta de crédito.
                <br></br>
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $80</h1>
        </div>

        {/* <hr className='line-separate' /> */}

        {/* <div className='child-container-comision'>
                          <div className='container-flex-row-start'>
                            <div>
                              <img width={60} height={60} src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672070919/WALLET%20BLACK/circulo_posnet_u11rua.png" />
                            </div>
                            <div>
                              <p className="titulo-comision"> Cobro con  tarjeta de débito </p>
                              <p className="descripcion-comision">
                                Con tu lector de Black.
                              </p>
                            </div>
                          </div>
                          <h1 className='tittle-web'> 0.5%* </h1>
                        </div> */}

        <div className="text-white bg-white h-[1px] w-full "> </div>

        <div className="child-container-comision">
          <div className="container-flex-row-start pt-4">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672070919/WALLET%20BLACK/circulo_enlace_rssv4x.png"
                alt="Cobro"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div>
              <p className="titulo-comision"> Cobro con link de pago </p>
              <p className="descripcion-comision">
                Generando tu link de pago personalizado con el monto que
                quieras.
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> 3%* </h1>
        </div>

        {/* <hr className='line-separate' />

                        <div className='child-container-comision'>
                          <div className='container-flex-row-start' >
                            <div>
                              <img width={60} height={60} src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672071796/WALLET%20BLACK/circulo_flecha_arriba_qtouhu.svg" />
                            </div>
                            <div>
                              <p className="titulo-comision">
                                Extracción de efectivo en Pago Fácil
                              </p>
                              <p className="descripcion-comision">
                                Solo tenés que ir con tu DNI a la sucursal más cercana.
                              </p>
                            </div>
                          </div>
                          <h1 className='tittle-web'> 0.5%* </h1>
                        </div>

                        <hr className='line-separate' />

                        <div className='child-container-comision'>
                          <div className='container-flex-row-start' >
                            <div>
                              <img width={60} height={60} src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672071795/WALLET%20BLACK/circulo_flecha_abajo_pvgbre.svg" />
                            </div>
                            <div>
                              <p className="titulo-comision">
                                Ingreso de efectivo en Pago Fácil
                              </p>
                              <p className="descripcion-comision">
                                Solo tenés que ir con tu DNI a la sucursal más cercana.
                              </p>
                            </div>
                          </div>
                          <h1 className='tittle-web'> 0.5%* </h1>
                        </div> */}
      </div>
    </section>
  );
};

export default Slider2;
