import { useState } from "react";

import { useSpring, animated } from "react-spring";
import "./beneficios.css";
import "swiper/css/pagination";
import { Helmet } from "react-helmet";

// Import Sections
import Hero from "./Hero";
// import Pais from "./Pais";
// import Artistas from "./Artistas";
// import Descuentos from "./Descuentos";
import Gastronomia from "./convenios/Gastronomia";
import Bebidas from "./convenios/Bebidas";
import Salud from "./convenios/Salud";
import Cafeteria from "./convenios/Cafereria";
// import Panaderia from "./convenios/Panaderia";
import Viajes from "./convenios/Viajes";
import Regalerias from "./convenios/Regalerias";
import Indumentaria from "./convenios/Indumentaria";
import Servicios from "./convenios/Servicios";
import Construccion from "./convenios/Construccion";
import Deportes from "./convenios/Deportes";
import Preguntas from "./Preguntas";

export default function Beneficios() {
    
  const [verTodos, setVerTodos] = useState(false);

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
  });

  return (
    <main className="beneficios-page-container">
      <Helmet>
          <title>Beneficios - Black Wallet</title>
          <meta name="description" content="Mire todos los beneficios que ofrece Black Wallet" />
      </Helmet>

      <animated.div style={fadeOut}>
        <Hero />
        {/* <Pais /> */}
        {/* <Artistas /> */}

        <div className="align-slider-right ">
          {/* <Descuentos /> */}

          {/* CONVENIOS */}
          <Gastronomia />
          <Bebidas />
          <Salud />

          <div className="slider-beneficios-con-logos">
            <Cafeteria />
            {/* <Panaderia /> */}
            <Viajes verTodos={verTodos} />

            {verTodos && (
              <>
                <Regalerias />
                <Indumentaria />
                <Servicios />
                <Construccion />
                <Deportes />
              </>
            )}

            <button
              className="boton-ver-todos flex flex-row items-center"
              onClick={() => setVerTodos(!verTodos)}
            >
              <span>{verTodos ? "Ver menos" : "Ver todos"}</span>
              <img
                className="mt-1"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1672314736/WALLET%20BLACK/Frame_428_1_wma9gt.svg"
                alt="arrow"
                loading="lazy"
                decoding="async"
                width={6}
                height={10}
              />
            </button>
          </div>
        </div>

        <Preguntas />
      </animated.div>
    </main>
  );
}
