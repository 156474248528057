// import Timer from "./Timer/Timer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import './home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

// Import Sections
import Hero from "./Hero";
import AhoraPodes from "./AhoraPodes";
import PagaCuotas from "./PagaCuotas";
// import ConoceArg from "./ConoceArg";
// import CompraEntradas from "./CompraEntradas";
import TelefonoCards from "./TelefonoCards";
import Seguridad from "./Seguridad";
import SumateBlack from "./SumateBlack";
import ASoloCero from "./ASoloCero";

AOS.init();

export default function Home() {
  return (
    <main className="home-container">
      <Helmet>
          <title>Black Wallet</title>
          <meta name="description" content="Bonus Black. Ser Blacker tiene beneficios exclusivos y descuentos en locales adheridos. · Black Social Club. Vos alentá, nosotros te bancamos." />
      </Helmet>

      <Hero />
      <AhoraPodes />
      <PagaCuotas />
      {/* <ConoceArg /> */}
      {/* <CompraEntradas /> */}
      <TelefonoCards />
      <Seguridad />
      <SumateBlack />
      <ASoloCero />
    </main>
  )
}
