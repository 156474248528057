const PYME = () => {
  return (
    <section className="container-flex-row tam-section-xl">
      <div className="child-comerciantes-items">
        <div className="child-container-media">
          <img
            width={300}
            height={412}
            src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672682513/WALLET%20BLACK/due%C3%B1oCampo_v6mibj.png"
            alt="hombre"
            loading="lazy"
            decoding="async"
          />
        </div>

        <div className="child-container-text font-ubuntu">
          <h1 className="tittle-web font-ubuntu">Para tu PYME</h1>
          <p className="text-web">
            Obtené todos los beneficios de un banco, sin burocracia y sin perder
            tiempo.
          </p>

          <div className="container-flex">
            <div className="container-card-flex-comerciante mb-4">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684139/WALLET%20BLACK/caminioon_grzta9.png" alt="leasing" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Préstamos Leasing
                </h5>
                <p className="text-[1em] font-[300]">
                  Ampliará tu capital de trabajo y tu maquinaria.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684115/WALLET%20BLACK/casaIcon_delnvc.png" alt="hipotecarios" loading="lazy" decoding="async" width={24} height={29} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Préstamos hipotecarios
                </h5>
                <p className="text-[1em] font-[300]">
                  Para terrenos, viviendas, locales y/o remodelaciones.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/bike_yf1mbj.png" alt="prendarios" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Préstamos Prendarios
                </h5>
                <p className="text-[1em] font-[300]">
                  A tasa preferencial para la compra de motos/ vehículos.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante mb-4">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/guita_cqf5y8.png" alt="pyme" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Préstamos Pyme
                </h5>
                <p className="text-[1em] font-[300]">
                  Un crédito de libre destino, con requisitos mínimos.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684340/WALLET%20BLACK/creditoo_gku1xq.png" alt="credito" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Tarjeta de Crédito
                </h5>
                <p className="text-[1em] font-[300]">
                  Accedé a una tarjeta Visa Black crédito.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/money_h42pgp.png" alt="aguinaldo" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Pago de aguinaldo u obligación con colaboradores
                </h5>
                <p className="text-[1em] font-[300]">De manera inmediata.</p>
              </div>
            </div>

            <div className="container-card-flex-comerciante mb-4">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/foquito_t5yf8i.png" alt="inversiones" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Inversiones
                </h5>
                <p className="text-[1em] font-[300]">
                  Invertí un porcentaje de tus ventas en nuestro portfolio.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/llavee_ryhltl.png" alt="alquiler" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Garantías de alquiler
                </h5>
                <p className="text-[1em] font-[300]">
                  Para tu comercio y/o vivienda sin costo.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/chequees_zyxqx0.png" alt="cheques" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Cambio de cheques
                </h5>
                <p className="text-[1em] font-[300]">
                  De manera inmediata con tasa preferencial.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/candadoo_h5wztr.png" alt="seguros" loading="lazy" decoding="async" width={37} height={37} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Seguros
                </h5>
                <p className="text-[1em] font-[300]">
                  Hasta un 50% de descuento en nuestra cartera.
                </p>
              </div>
            </div>

            <div className="container-card-flex-comerciante">
              <div className="card-image">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672684114/WALLET%20BLACK/metal_fsfeie.png" alt="contactos" loading="lazy" decoding="async" width={36} height={36} />
              </div>
              <div className="card-text">
                <h5 className="font-[500] text-[1.2em] w-[60%] mb-2">
                  Red de contactos
                </h5>
                <p className="text-[1em] font-[300]">
                  Encontrarás contactos que te ayuden a potenciar tu negocio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PYME;
