import { useEffect } from 'react';
import lozad from 'lozad';
import './Lozad.css';

export const LazyImage = ({ src, alt, width, height, className = '' }) => {
  useEffect(() => {
    const observer = lozad('.lozad', {
        threshold: 0.75,
        loaded: function(el) {
            el.classList.add('loaded');
        }
    });
    observer.observe();
  }, []);

  return <img data-src={src} alt={alt} className={`lozad ${className}`} width={width} height={height} />;
};

export const LazyVideo = ({ src, type, poster, width, height, className = '' }) => {
  useEffect(() => {
    const observer = lozad('.lozad', {
        threshold: 0.75,
        loaded: function(el) {
            el.classList.add('loaded');
        }
    });
    observer.observe();
  }, []);

  return (
    <video
      data-src={src}
      poster={poster}
      width={width}
      height={height}
      className={`lozad ${className}`}
      muted
      loop
      autoPlay
    >
      {type && <source src={src} type={type} />}
    </video>
  );
};