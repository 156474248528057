import PdfComisiones from './Comisiones NOV-DIC 23.pdf';

const Slider1 = ({ vistaMobile }) => {
  return (
    <section className="comisiones-slider1">
      <div className="container-comision-text">
        <h1 className="tittle-comision-web text-[35px] mb-2 font-bold">
          {" "}
          No tiene costo{" "}
        </h1>
      </div>

      <div className="comentarios-clientes-comision">
        <div className="child-container-comision pb-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672060173/WALLET%20BLACK/asdasdasd_r6ocym.svg"
                alt='Abertura'
                loading='lazy'
                decoding='async'
              />
            </div>
            <div>
              <p className="titulo-comision"> Apertura de cuenta </p>
              <p className="descripcion-comision">
                Crear tu cuenta Black es gratis. Solo necesitás unos minutos y
                tener tu DNI a mano
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $0</h1>
        </div>

        <div className="text-white bg-white h-[1px] w-full "> </div>

        <div className="child-container-comision py-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672070919/WALLET%20BLACK/circulo_llave_inglesacirculo_llave_inglesa_md0fba.png"
                alt='Mantenimiento'
                loading='lazy'
                decoding='async'
              />
            </div>
            <div>
              <p className="titulo-comision"> Mantenimiento </p>
              <p className="descripcion-comision">
                Utilizá Black sin costo alguno, del mantenimiento nos encargamos
                nosotros.
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $0</h1>
        </div>

        <div className="text-white bg-white h-[1px] w-full "> </div>

        <div className="child-container-comision py-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1675712322/WALLET%20BLACK/Frame_427_rkjlkh.svg"
                alt='QR'
                loading='lazy'
                decoding='async'
              />
            </div>
            <div>
              <p className="titulo-comision"> Cobro con QR </p>
              <p className="descripcion-comision">
                Sin comisiones durante los primeros 3 meses y hasta $20.000 por
                operación.
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $0</h1>
        </div>

        <div className="text-white bg-white h-[1px] w-full "> </div>

        <div className="child-container-comision py-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672071186/WALLET%20BLACK/circulo_flechitascirculo_flechitas_qm9ktk.svg"
                alt='Transferencia'
                loading='lazy'
                decoding='async'
              />
            </div>
            <div>
              <p className="titulo-comision"> Transferencia </p>
              <p className="descripcion-comision">
                Son inmediatas y gratuitas a cualquier cuenta bancaria y/o
                billetera virtual.
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $0</h1>
        </div>

        <div className="text-white bg-white h-[1px] w-full "> </div>

        <div className="child-container-comision py-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672071356/WALLET%20BLACK/ciruclo_tarjeta_nmblmk.png"
                alt='Tarjetas'
                loading='lazy'
                decoding='async'
              />
            </div>
            <div>
              <p className="titulo-comision"> Tarjetas </p>
              <p className="descripcion-comision">
                La emisión de nuestras tarjetas, su entrega, y su mantenimiento
                no tienen costo alguno.
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $0</h1>
        </div>

        <div className="text-white bg-white h-[1px] w-full "> </div>

        <div className="child-container-comision pt-4">
          <div className="container-flex-row-start">
            <div>
              <img
                width={60}
                height={60}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672071188/WALLET%20BLACK/circulo_ticket_oychiq.svg"
                alt='Servicios'
                loading='lazy'
                decoding='async'
              />
            </div>
            <div>
              <p className="titulo-comision"> Pago de servicios </p>
              <p className="descripcion-comision">
                No cobramos comisiones ni recargos a los pagos de tus servicios.
                Pagás lo que indique la factura.
              </p>
            </div>
          </div>
          <h1 className="tittle-web font-bold text-[48px]"> $0</h1>
        </div>
      </div>
      <div className="container-comision-subtext" style={{ display: vistaMobile ? 'none' : 'block' }}>
        <p>
          {" "}
          * Con dinero disponible en la Black Wallet de tu cliente y
          acreditación inmediata. <br></br>{" "}
          <span>
            <a href={PdfComisiones} target="_blank" rel='noreferrer noopener'>
              Descargá la planilla completa de costos {">"}
            </a>
          </span>{" "}
        </p>
      </div>
    </section>
  );
};

export default Slider1;
