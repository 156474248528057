import { Link } from "react-router-dom";

import "./footer.css";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="container-footer">
      <div className="child-container-footer">
        <div className="footer-flex-row">
          <div className="footer-flex-col footer-logo">
            <Link to="/">
              <img
                width={160}
                height={150}
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1671551488/WALLET%20BLACK/icon-black_rba7tb.svg"
                alt="Logo"
                loading="lazy"
                decoding="async"
              />
            </Link>
            <p className="style-copyright">Copyright {year}</p>
          </div>

          <div className="footer-flex-col">
            <Link className="items-navbar" to="/nosotros">
              NOSOTROS
            </Link>
            <Link className="items-navbar" to="/comisiones">
              COMISIONES
            </Link>
            <Link className="items-navbar" to="/beneficios">
              BENEFICIOS
            </Link>
            <Link className="items-navbar" to="/preguntas-frecuentes">
              PREGUNTAS FRECUENTES
            </Link>
            {/* <Link className='items-navbar' to="/robo-o-extravio">ROBO O EXTRAVÍO</Link> */}
            <Link className="items-navbar" to="/comerciantes">
              COMERCIANTES
            </Link>
            {/* <Link className='items-navbar' to="/tarjetas">TARJETAS</Link> */}
          </div>
        </div>

        <div className="flex justify-center flex-col items-center footer-platforms-socials">
          <div className="flex flex-col">
              <a
                id="playstore"
                href="https://play.google.com/store/apps/details?id=app.blackwallet.ar"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1671803922/WALLET%20BLACK/googleplay_qhwv7l.png" alt="Google Play" loading="lazy" decoding="async" width={158} height={45} />
              </a>

              <a
                id="appstore"
                href="https://apps.apple.com/us/app/black-wallet/id1661314614"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1671803922/WALLET%20BLACK/appstore_teryy5.png" alt="Apple Store" loading="lazy" decoding="async" width={160} height={52} />
              </a>
          </div>

          <div className="flex flex-row items-center justify-center">
            <a href="https://www.instagram.com/blackwallet.ar/" target="_blank" rel="noreferrer noopener">
              <img
                className="w-4  h-4"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1671801706/WALLET%20BLACK/icon-insta_hb9a93.svg"
                alt="Instagram"
                loading="lazy"
                decoding="async"
                width={16}
                height={16}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCPe10SfRCs-vlAAgtTlsmZA"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="w-4  h-4"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1671801706/WALLET%20BLACK/icon-youtube_wfvxqj.svg"
                alt="Youtube"
                loading="lazy"
                decoding="async"
                width={16}
                height={16}
              />
            </a>
            <a href="https://www.facebook.com/blackwallet.ar" target="_blank" rel="noreferrer noopener">
              <img
                className="w-4 h-4"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690812827/WALLET%20BLACK/footer/Union_zbxntv.svg"
                alt="Facebook"
                loading="lazy"
                decoding="async"
                width={16}
                height={16}
              />
            </a>
            <a href="https://twitter.com/blackwallet_ar" target="_blank" rel="noreferrer noopener">
              <img
                className="mt-1 w-5 h-5"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1692365951/WALLET%20BLACK/footer/Twitter_X-01_1_z9cnql.svg"
                alt="twitter"
                loading="lazy"
                decoding="async"
                width={20}
                height={20}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/black-wallet-ar/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="mt-[0.6rem] w-6 h-6 "
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690812579/WALLET%20BLACK/footer/Union_yp8h6x.svg"
                alt="Linkedln"
                loading="lazy"
                decoding="async"
                width={24}
                height={24}
              />
            </a>
            <a
              href="https://www.tiktok.com/@blackwallet.ar?_t=8dNbdYlqg0E&_r=1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="mt-[0.8rem] w-6 h-6"
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1690812578/WALLET%20BLACK/footer/Vector_kbmgyx.svg"
                alt="Tik Tok"
                loading="lazy"
                decoding="async"
                width={24}
                height={24}
              />
            </a>
          </div>

          <p className="style-copyright copyright-responsive">
            Copyright {year}
          </p>
        </div>
      </div>
    </footer>
  );
}
