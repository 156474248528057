import { useMediaQuery } from 'react-responsive'
import { LazyVideo } from '../Lozad/Lozad';

import './hero.css'

export default function Hero(props) {

  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)", });

  const backgroundVideoMobile = props.backgroundVideoMobile;
  const backgroundVideo = props.backgroundVideo;
  const titulo = props.titulo;
  const subtitulo = props.subtitulo;
  const poster = props.poster;
  // const botonesDescargar =props.botonesDescargar;
  const videoSource = vistaMobile ? backgroundVideoMobile : backgroundVideo;

  return (
    <div className='hero-container'>
        <div className={"video-container-hero"}>
          <LazyVideo
           src={videoSource}
           type={''}
           poster={poster}
           width={500}
           height={500}
           className='hero-video-background-home'
          />
        </div>
      <div className='flex items-center flex-col'>
        <h1 className='text-[56px] font-bold font-ubuntu mb-4 text-white'>{titulo}</h1>
        <h5 className='text-center text-white font-[500] font-ubuntu w-full  xs:w-[50%] px-16 text-[18px] xs:text-[22px]'>{subtitulo}</h5>
        {/* {vistaMobile && botonesDescargar=="Activar" &&
      <div className="scroll-seccion-botones">
          <button>
            <img alt="google play" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672685993/WALLET%20BLACK/play_store-01_wdahsu.svg" />
            <div>
              <p className="button-scroll-sub">Descargalo en</p>
              <p className="button-scroll-titulo">Google Play</p>
            </div>
          </button>
          <button>
            <img alt="apple store" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1672744949/WALLET%20BLACK/apple-logo-01-01_el9iip.svg" />
            <div>
              <p className="button-scroll-sub">Descargalo en</p>
              <p className="button-scroll-titulo">App Store</p>
            </div>
          </button>
        </div>} */}
      </div>
    </div>
  )
}
