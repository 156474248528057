import { Swiper, SwiperSlide } from "swiper/react";

const Viajes = ({ verTodos }) => {
  return (
    <article className="beneficios-convenio">
      <div
        className={
          verTodos
            ? "slider-container-beneficios"
            : "slider-container-beneficios slider-container-with-transparency"
        }
      >
        <div className="slider-container-beneficios">
          <p className="titulo-slider-beneficio mb-4">Viajes y recreación</p>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1500: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
            }}
            slidesPerView={6}
            spaceBetween={0}
          >
            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1696864748/WALLET%20BLACK/Beneficios/termasdelsaladio_wud7dg.svg" alt="saladio" loading="lazy" decoding="async" width={190} height={55} />
              </div>
              <h5 className="titulo-comentario-cliente">Hasta 10% off</h5>
              <p className="text-[12px]">
                Avenida Intendente Ferrer y Camino a la Rivera - Calle 145 Nº
                2075, General Belgrano
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1696864841/WALLET%20BLACK/Beneficios/caba%C3%B1asKimey_tqxces.svg" alt="kimey" loading="lazy" decoding="async" width={136} height={60} />
              </div>
              <h5 className="titulo-comentario-cliente">20% off</h5>
              <p className="text-[12px]">
                General Belgrano, Buenos Aires, Argentina
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1690481098/WALLET%20BLACK/Beneficios/test_ee7vbv.svg" alt="test" loading="lazy" decoding="async" width={129} height={59} />
              </div>
              <h5 className="titulo-comentario-cliente">Pagá con Black</h5>
              <p className="text-[12px]">
                Accedé a planes de financiación personalizados
              </p>
            </SwiperSlide>

            {/* <SwiperSlide className="swipper-beneficios-con-logo">
                    <div className="box-image-logo-container">
                      <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1690481098/WALLET%20BLACK/Beneficios/avellaneda_wq0ooi.svg" />
                    </div>
                    <h5 className="titulo-comentario-cliente">
                      Pagá con Black
                    </h5>
                    <p className="text-[12px]">En locales adheridos</p>
                  </SwiperSlide> */}

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1693419511/WALLET%20BLACK/Beneficios/Recurso_1marca_soria_1_me4idu.svg" alt="soria" loading="lazy" decoding="async" width={69} height={79} />
              </div>
              <h5 className="titulo-comentario-cliente w-[70%] text-[17px]">
                Pagá con Black
              </h5>
              <p className="text-[12px]">
                Bicicletería Soria Av. 60 675, La Plata
              </p>
            </SwiperSlide>

            <SwiperSlide className="swipper-beneficios-con-logo">
              <div className="box-image-logo-container">
                <img src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/v1700228613/WALLET%20BLACK/Beneficios/-_km9_outdoor_1_ojyuyg.svg" alt="km9" loading="lazy" decoding="async" width={127} height={42} />
              </div>
              <h5 className="titulo-comentario-cliente w-[70%] text-[17px]">
                Pagá con Black
              </h5>
              <p className="text-[12px]">
                Au Dr. Ricardo Balbín Km 9 Sarandí, Parque Comercial Avellaneda
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </article>
  );
};

export default Viajes;
